<template lang="pug">
    v-container.videos.px-0.text-center(fluid)
        // Snacbar
        v-snackbar(v-model='snackbar.show' :color='snackbar.color' right top :timeout="4000")
          | {{snackbar.message}}
          v-btn(dark='' text='' @click='snackbar.show = false')
            | Close
        // content
        v-card
            v-card-title
                | Confirm your purchase
                v-spacer
                    v-icon(aria-label="Close" @click="$emit('close')")
                        | mdi-close
            // Validar si no esta subscripto
            v-card-text.body-1.text-center(v-if="!subscription")
                | You are not subscribed to any plan, you can subscribe or pay directly by credit card
            // Validar tokens de descarga
            v-card-text.body-1.text-center(v-else-if="subscription && item.type.includes('video') && tokens.video > 0")
              | You have {{tokens.video}} downloads available, if you purchase this video there will be {{tokens.video - 1}} downloads available
            v-card-text.body-1.text-center(v-else-if="subscription && item.type.includes('image') && tokens.image > 0")
              | You have {{tokens.image}} downloads available, if you purchase this image there will be {{tokens.image - 1}} downloads available
            v-card-text.body-1.text-center(v-else)
              | No downloads available, the purchase will be made directly with your credit card
            v-card-actions.text-center
                v-spacer
                    v-btn(v-if="!subscription || tokens.video === 0" color='red darken-1', @click='checkout' :loading="loading").px-2
                      span.px-2 1 time purchase
                    v-btn(v-if="subscription && tokens.video > 0" color='red darken-1', @click='buy' :loading="loading") Download
                    v-btn(v-if="!subscription" color='blue darken-1', @click="subscribe") Subscribe
</template>

<script>
  /* eslint-disable */
  import loaders from '@/plugins/mixins/loaders'
  import axios from 'axios'
  const stripe = Stripe('pk_live_Dfd8kq0KeASDVrcLQWoZazR000xAgaateT')
  import * as vars from '@/vars.json';
  export default {
    mixins: [loaders],

    props: {
      item: Object,
    },

    data: () => ({
      snackbar: {
        show: false,
        message: '',
        color: 'red',
      },
      loading: false,
    }),

    computed: {
      downloads () {
        return this.$store.getters['downloads/getDownloads']
      },
      tokens () {
        return this.$store.getters['downloads/getTokens']
      },
      subscription () {
        if (this.$store.getters['subscriptions/getStripe']) {
          return this.$store.getters['subscriptions/getStripe'].subscription || null
        } else {
          return null
        }
      },
    },

    created () {
      const vm = this
      vm.loadDownloads()
    },

    methods: {
      showSnackbar (color, message) {
        const vm = this
        vm.snackbar.color = color
        vm.snackbar.message = message
        vm.snackbar.show = true
        vm.loading = false
        setTimeout(function(){
          vm.$emit('close')
        }, 2000);
      },

      validateDownload (url) {
        const vm = this
        let found = null
        if (vm.downloads) {
          found = vm.downloads.find(element => element.url === url)
        }
        if (found) {
          vm.showSnackbar('red', 'You have already downloaded this content')
        }
        return found
      },
      buy () {
        const vm = this
        if (!vm.validateDownload(vm.item.url)) {
          vm.loading = true
          vm.item.method = 'token'
          vm.$store.dispatch('downloads/createDownloads', vm.item).then(async res => {
            vm.showSnackbar('success', 'The purchase was made successfully')
          })
        }
      },
      subscribe () {
        this.$router.push('Plans')
      },
      checkout () {
        const vm = this
        if (!vm.validateDownload(vm.item.url)) {
          vm.loading = true
          const product = {
            id: vm.item.id
          }
          // console.log(product)
          axios.post(vars.urlProd+'/webCheckout', product).then(res => {
            // console.log(res)
            vm.loading = false
            stripe.redirectToCheckout({
              sessionId: res.data.id
            }).then(result => {
              // console.log(result)
            })
          }).catch(e => {
            // console.log(e.response)
          })
        }
      },
    },
  }
</script>
